.appTitleContainer {
    width: 100%;
    vertical-align: bottom;
}

.appLogo {
    display: inline-block;
    padding: 0px 5px;
}

.version {
    display: inline-block;
    padding: 0px 5px;
    font-size: .7em;
}

