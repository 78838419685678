html {
  height: 100%;
  display: block;
}

body {
  margin: 0;
  height: 100%;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #93abb2;
}

div {
  display: block;
}

#root {
  height: 100%;
}
 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.appContainer {
  height: 100%;
  padding: 5px;
}

.title {
  flex: 0 1 auto;
  width: 100%;
  vertical-align: bottom;
}

.footer {
  flex: 0 1 auto;
}

.contentContainer {
  flex: 1 1 auto;
  display: flex;
  padding: 5px;
  background: #bac4c9;
  height: 100%;
}

.contentBody {
  flex: 1 1 auto;
  padding: 5px;
  background: #f7f7f7;
  height: 100%;
}